<template>


  <the-modal :isBig="true" @closeModal="closeModal">
   <template v-slot:header>
     <h5 v-if="user!==null">Zu Reiseplan hinzufügen</h5>
   </template>
   <template v-slot:body>
    <div v-if="user==null" class="not-logged-in">
      <img src="/assets/logo/WOLFSBURGER LUPE-Icon.png" class="logo" alt="Lupe Logo">
      <center>
        <span>Erstelle jetzt ganz einfach ein Profil oder melde dich an und lege eigene Merklisten an und vieles mehr!</span>
        <div class="login-register-links">
          <button class="btn btn-block" @click="LoginRoute">Anmelden</button>
          <button class="btn btn-block" @click="RegisterRoute">Registrieren</button>
        </div>
      </center>
    </div>
    <div v-else class="top-margin">
      <button class="btn btn-fill icon-before" style="width:100%;" v-if="!isInFavorits" @click="addToFavoriten">
        <div class="icon-text-box">
          <i class="material-icons" aria-hidden="true">star</i>
          <span class="text-style">Als Favorit speichern</span>
        </div>
      </button>

      <button class="btn btn-fill icon-before mt10" style="width:100%;" v-else @click="removeFromFavoriten">
        <div class="icon-text-box">
          <i class="material-icons" aria-hidden="true">delete</i>
          <span class="text-style">Aus Favoriten entfernen</span>
        </div>
      </button>

      <div class="mt20 text-center add-to-list" v-if="hasCollections">

        <button class="btn btn-fill icon-before mt10" style="width:100%;" @click="toggleExpandableElement('list')">
          <div class="icon-text-box">
            <i class="material-icons" aria-hidden="true">list</i>
            <span class="text-style">Zu Liste hinzufügen</span>
          </div>
        </button>
      </div>
      <div class="my-collections mt20" v-show="showList">

        <list-group 
        style="margin-bottom: 20px;" 
        v-if="userCollections.length > 0"
        @addToList="beforeAddContentToCollection" 
        @removeFromList="beforeRemoveContentFromCollection" 
        :content="content" 
        :title="'Meine Listen'"
        :collections="userCollections" 
        :expanded="true"/>

        <list-group 
        style="margin-bottom: 20px;"
        v-if="sharedCollections.length > 0"
        @addToList="beforeAddContentToCollection"
        @removeFromList="beforeRemoveContentFromCollection" 
        :content="content"  
        :title="'Mit mir geteilte Listen'" 
        :collections="sharedCollections" 
        :expanded="false"/>

        <list-group 
        v-if="institutionCollections.length > 0"
        style="margin-bottom: 20px;" 
        @addToList="beforeAddContentToCollection" 
        @removeFromList="beforeRemoveContentFromCollection" 
        :content="content" 
        :title="'Listen meiner Institution'"
        :collections="institutionCollections"
        :expanded="false"/>

        <div v-if="appsCollections.length>0">
          <list-group 
          v-for="app in appsCollections" :key="app.name"
          @addToList="beforeAddContentToCollection" 
          @removeFromList="beforeRemoveContentFromCollection" 
          :content="content" 
          :title="app.name + ' Listen'" 
          :collections="app.collections" 
          :expanded="false"
          style="margin-bottom: 20px;"/>
        </div>

      </div>

      <div class="text-center new-collection-section">
        <span class="description-text">Erstelle deine eigene Merkliste, um Favoriten zu ordnen:</span>
        <button class="btn btn-active btn-block" @click="showCreateModal">Merkliste erstellen</button>
      </div>
    </div>   
  </div>

</template>

<template v-slot:footer>
  <button type="button" class="close btn-block" data-dismiss="modal" aria-label="Close" @click="closeModal">Abbrechen</button>   
</template>

</the-modal>

</template>
<script>
  import { addContentToCollection, removeContentFromCollection } from '@/api/collection';
  import ListGroup from './listgroup';

  export default {
    name: 'CollectionModal',
    components: {
      TheModal: () => import('@/components/modals/show.vue'),
      ListGroup,
    },
    props: {
      content: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        loading: false,
        showList: false,
        showAddNewList: false,
      }
    },
    mounted() {
    },
    computed: {
      user(){
        return this.$store.state.user;
      },
      hasCollections(){
        if(this.userCollections.length>0||this.sharedCollections.length>0||this.institutionCollections.length>0||this.appsCollections.length>0){
          return true;
        }
        return false;
      },
      collections(){
        return this.$store.getters.getUserCollections;
      },
      institutionCollections(){
        if(this.collections != null && Object.prototype.hasOwnProperty.call(this.collections,'institution')){
          return this.collections.institution;
        }
        return [];
      },
      userCollections(){
        if(this.collections != null && Object.prototype.hasOwnProperty.call(this.collections,'own')){
          return this.collections.own;
        }
        return [];
      },
      sharedCollections(){
        if(this.collections != null && Object.prototype.hasOwnProperty.call(this.collections,'shared')){
          return this.collections.shared;
        }
        return [];
      },
      appsCollections(){
        if(this.collections != null && Object.prototype.hasOwnProperty.call(this.collections,'apps')){
          return this.collections.apps;
        }
        return [];
      },
      theFavoritenCollection(){
        /*check if the user has any personal collections*/
        if(this.userCollections.length>0){
          /*let's look in this set of collections for one with the title "Favoriten"*/
          for(var i = 0; i < this.userCollections.length; i++){
            if(this.userCollections[i].name == "Favoriten"){
              return this.userCollections[i];
            }
          }
        }
        return null;
      },
      isInFavorits(){
        if(this.theFavoritenCollection != null && this.isContentInCollection(this.theFavoritenCollection)){
          return true; 
        }
        return false;
      },
    },
    methods: {
      isContentInCollection(collection){
        for(var i=0; i<collection.contents.length; i++){
          if(collection.contents[i].id == this.content.id){
            return true;
          }
        }
        return false;
      },
      addToFavoriten(){
        /*add a certain content to Favoriten*/
        if(this.theFavoritenCollection != null){
          this.beforeAddContentToCollection(this.theFavoritenCollection);
        }
        else{
          /*if there is no FavoritenCollection*/
          this.addContentToCollection('Favoriten')
        }
      },
      toggleExpandableElement(element){
        if(element == "list"){
          this.showList = this.showList==true?false:true;
          /*if the list is shown, the add new list form should be hidden*/
          if(this.showList == true){
            this.showAddNewList = false;
          }
        }
        if(element == "add-list"){
          this.showAddNewList = this.showAddNewList==true?false:true;
          /*if the list is shown, the add new list form should be hidden*/
          if(this.showAddNewList == true){
            this.showList=false;
          }
        }
      },
      beforeRemoveContentFromCollection(collection){
        this.removeContentFromCollection(collection);
      },
      removeFromFavoriten(){
        this.removeContentFromCollection(this.theFavoritenCollection);
      },
      removeContentFromCollection(collection){
        this.loading = true;
        removeContentFromCollection(this.content.id,collection.id)
        .then(() => {
          this.content.in_collection = false;

          this.$notify({
            duration: 2500,
            title: 'Inhalt entfernt!',
            text: 'Der Inhalt wurde erfolgreich aus der Merkliste entfernt.'
          });

          this.$emit('reloadCollection',collection.id);

        })
        .catch(error => {

          console.log(error);

          this.$notify({
            type: 'error',
            duration: 2500,
            title: 'Fehler!',
            text: 'Der Inhalt konnte nicht aus der Merkliste entfernt werden.'
          });

        })
        .finally(() => {
          this.$store.dispatch('loadUserCollections');
          this.$store.dispatch('loadUser');
          this.loading = false;
        });

      },
      LoginRoute(){
        this.closeModal();
        this.$router.push({name:'Login'});
      },
      RegisterRoute(){
        this.closeModal();
        this.$router.push({name:'Signup'});
      },
      showCreateModal(){
        this.$emit("showCreateModal");
      },
      closeModal(){
        this.$emit("closeModal");
      },
      beforeAddContentToCollection(collection){
        if(this.isContentInCollection(collection)){
          this.$confirm('Der Inhalt ist bereits in dieser Liste gespeichert. Möchtest du diesen Inhalt erneut hinzufügen?', {
            confirmButtonText: 'Hinzufügen',
            cancelButtonText: 'Abbrechen'
          }).then(() => {
            /*user said ok*/
            this.addContentToCollection(collection);
          }).catch(() => {
           /*do nada*/
         });
        }
        else{
          this.addContentToCollection(collection);
        }
      },
      addContentToCollection(collection){
        this.loading = true;
        addContentToCollection(this.content,collection)
        .then(() => {
          this.content.in_collection = true;
          this.$notify({
            duration: 2500,
            title: 'Inhalt gespeichert!',
            text: 'Der Inhalt wurde erfolgreich zu der Merkliste hinzugefügt.'
          });
        })
        .catch(error => {
          console.log(error);
          this.$notify({
            type: 'error',
            duration: 2500,
            title: 'Fehler!',
            text: 'Der Inhalt konnte nicht gespeichert werden.'
          });
        })
        .finally(() => {
          this.$store.dispatch('loadUserCollections');
          this.$store.dispatch('loadUser');
          this.loading = false;
        });
      },
    },              
  };
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';

  .modal-content .scrollable {
    flex-wrap: nowrap;
    flex-direction: row;
    overflow-x: scroll;
    scrollbar-width: auto;
    padding: 0px 5px;
  }

  .modal-content .scrollable .scrollable-card {
    margin-bottom: 15px;
  }

  .not-logged-in {
    margin-bottom: 20px;
  }

  .login-register-links {
    margin-top: 20px;
  }

  .logo {
   display: block;
   margin-left: auto;
   margin-right: auto;
   width: 50%;
   margin-bottom: 20px;
 }

 .close {
  font-size: 14px;
  border: 1px solid;
  padding: 0.75rem 2.6rem;
  border-radius: 100px;
}

.add-to-list {
  margin-bottom: 20px;
}

.icon-text-box {
  display: inline-flex;
  align-items:center;
}

.text-style {
  padding-top: 4px;
  padding-left: 5px;
}

.top-margin, .reiseplan-button {
  margin-top:15px;
}

.new-collection-section {
  margin-top: 30px;
}
</style>
